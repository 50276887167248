import React from "react"
import { PropTypes } from "prop-types"

import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import Button from "@mui/material/Button"
import Typography from "@mui/material/Typography"
import PersonSharpIcon from "@mui/icons-material/PersonSharp"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
// import WarningIcon from "@mui/icons-material/Warning"
import { getTierBgColor, isFacilityWithCopay } from "../../utils/common"
import { ReactComponent as FacilityIcon } from "../../assets/images/prov_facilityIcon.svg"
import { ReactComponent as TierIcon } from "../../assets/images/tier_starIcon.svg"

const ProviderList = ({
    handleOpen,
    providerDetails,
    handleLoadMore,
    providerDetailsDataLength,
    currentProviderDetails
}) => {
    const getByTitle = (title) => {
        if (title === "Practitioner,Physcian,V") {
            return "Healthcare Providers & Practices"
        } else if (title === "HOSPITAL,Facility,GROUP/CLINIC") {
            return "Hospitals, Facilities & Clinics"
        } else if (title === "specialties") {
            return "Specialists & Specialty Types"
        } else if (title === "procedureCode" || title === "diagnosisCode") {
            return "Conditions & Procedures"
        }
    }
    const getCodeType = () => {
        return currentProviderDetails?.codeType !== "diagnosisCode"
    }
    const checkProviderLengthStatus = () => {
        return providerDetailsDataLength > providerDetails.length
    }
    const providerVisit = (item) => {
        return (
            <div>
                <b>
                    {item?.override_rate !== null || item?.default_rate !== null
                        ? "$"
                        : ""}
                    {item?.override_rate !== null
                        ? item?.override_rate
                        : item?.default_rate}
                </b>{" "}
                out-of-pocket cost for :{" "}
                {item?.benefitcode_text ||
                    "Office Visit for New Patient: Normal"}
                <br />
            </div>
        )
    }
    const procedureVisit = (item) => {
        return (
            <div className="procedureVisitList">
                {item?.override_rate !== null || item?.default_rate !== null ? (
                    <div>
                        <b>
                            {item?.override_rate !== null ||
                            item?.default_rate !== null
                                ? "$"
                                : ""}
                            {item?.override_rate !== null
                                ? item?.override_rate
                                : item?.default_rate}
                        </b>{" "}
                        out-of-pocket cost for :{" "}
                        {item?.benefitcode_text ||
                            "Office Visit for New Patient: Normal"}
                        <br />
                    </div>
                ) : (
                    ""
                )}
                {getCodeType() && item?.outpatient_rate !== null ? (
                    <div>
                        <b>
                            {item?.outpatient_rate !== null ? "$" : ""}
                            {item?.outpatient_rate}
                        </b>{" "}
                        out-of-pocket cost for : Outpatient Visit
                        <br />
                    </div>
                ) : (
                    ""
                )}
                {getCodeType() && item?.inpatient_rate !== null ? (
                    <div>
                        <b>
                            {item?.inpatient_rate !== null ? "$" : ""}
                            {item?.inpatient_rate}
                        </b>{" "}
                        out-of-pocket cost for : Inpatient Visit
                        <br />
                    </div>
                ) : (
                    ""
                )}
            </div>
        )
    }
    const getCutomMessageContent = (currentProviderDetail, item) => {
        if (
            currentProviderDetail &&
            currentProviderDetail.title === "procedureCode"
        ) {
            return ""
        } else {
            return (
                <div className="provider-custom-message">
                    <div>{item?.custom_message}</div>
                </div>
            )
        }
    }
    const facilityContent = () => {
        return (
            <div className="facilityResultList">
                <b>
                    Your facility out-of-pocket cost will be based on the tier
                    of the physician who treats you at the facility. To see
                    those facility costs by tier, please see your benefit
                    summary or perform a search for the procedure or service
                    your physician will perform at the facility.
                </b>
            </div>
        )
    }
    const visitContent = (currentProviderDetails, item) => {
        if (
            currentProviderDetails &&
            currentProviderDetails.title === "procedureCode"
        ) {
            return procedureVisit(item)
        } else {
            return providerVisit(item)
        }
    }
    return (
        <>
            {currentProviderDetails?.title && (
                <div className="cards-heading">
                    Below are providers matching{" "}
                    <span className="provider-title">
                        {getByTitle(currentProviderDetails?.title)}
                    </span>{" "}
                    who may be able to help you. Please contact the provider to
                    confirm availability.
                </div>
            )}
            <div className="provider-inner-contianer">
                {providerDetails.map((item, i) => {
                    return (
                        <div className="card-wrapper" key={i}>
                            {i === 0 && (
                                <div className="seach-doctor-head">
                                    <b>Searched Doctor</b>
                                    <br />
                                    <br />
                                </div>
                            )}
                            {i === 1 && (
                                <div className="seach-doctor-head">
                                    <br />
                                    <b>Other Recommendations</b>
                                    <br />
                                    <br />
                                </div>
                            )}
                            <Card
                                className={`${
                                    providerDetails.length - 1 === i
                                        ? "last-element"
                                        : ""
                                } ProviderResults-card`}
                                sx={{ minWidth: "99%" }}
                            >
                                {item?.cardType === "preferred" ? (
                                    <>
                                        <div className="desig-header preferred-card">
                                            <div className="desig-left-section">
                                                <img
                                                    src={item?.provider_logo}
                                                />
                                                <div className="desig-seconday-section">
                                                    <Typography
                                                        sx={{ fontSize: 16 }}
                                                        color="text.secondary"
                                                        component="div"
                                                        gutterBottom
                                                        className="name-flds"
                                                    >
                                                        {item?.provider_name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ mb: 1.5 }}
                                                        component="div"
                                                        color="text.secondary"
                                                    >
                                                        {
                                                            item?.provider_specialty
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>

                                            <div className="tier-container">
                                                <div className="StarIcon-icon">
                                                    <img
                                                        src={
                                                            item?.employer_logo
                                                        }
                                                    />
                                                </div>
                                                <div className="subtext-final-tier">
                                                    Preferred
                                                </div>
                                            </div>
                                        </div>
                                        <div className="result-card-title badge-margin mat-card-header">
                                            <div className="mat-card-header-text">
                                                <div className="mat-card-subtitle">
                                                    <div className="result-card-title">
                                                        {/* <span> 0.24 miles · </span> */}
                                                    </div>
                                                    <div></div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item?.preferred_content
                                                        }}
                                                        className="result-card-title"
                                                    ></div>
                                                </div>
                                            </div>
                                        </div>
                                        <CardContent className="result-card-title mat-card-content card-footer-action">
                                            <Typography
                                                component={"div"}
                                                variant="body2"
                                                className="alignment-mat-icon"
                                            >
                                                <div>
                                                    <b>
                                                        {item?.override_rate !==
                                                            null ||
                                                        item?.default_rate !==
                                                            null
                                                            ? "$"
                                                            : ""}
                                                        {item?.override_rate !==
                                                        null
                                                            ? item?.override_rate
                                                            : item?.default_rate}
                                                    </b>{" "}
                                                    out-of-pocket cost for :{" "}
                                                    {item?.beneficiary_text}
                                                    <br />
                                                    <div>
                                                        <strong>
                                                            {
                                                                item?.copay_context
                                                            }
                                                        </strong>
                                                    </div>
                                                </div>
                                            </Typography>
                                        </CardContent>
                                        <CardContent className="result-card-title mat-card-content card-footer-action d-flex justify-content-end">
                                            <Button
                                                variant="contained"
                                                onClick={() =>
                                                    window.open(
                                                        item?.button_hyperlink,
                                                        "_blank"
                                                    )
                                                }
                                                endIcon={<OpenInNewIcon />}
                                            >
                                                {item?.button_text}
                                            </Button>
                                        </CardContent>
                                    </>
                                ) : (
                                    <>
                                        <div className="desig-header providers-card">
                                            <div className="desig-left-section">
                                                <PersonSharpIcon className="desig-left-icon" />
                                                <div className="desig-seconday-section">
                                                    <Typography
                                                        sx={{ fontSize: 16 }}
                                                        color="text.secondary"
                                                        component="div"
                                                        gutterBottom
                                                        className="name-flds"
                                                    >
                                                        {item?.provider_name}
                                                    </Typography>
                                                    <Typography
                                                        sx={{ mb: 1.5 }}
                                                        component="div"
                                                        color="text.secondary"
                                                    >
                                                        {
                                                            item?.speciality_category
                                                        }
                                                    </Typography>
                                                </div>
                                            </div>
                                            {!isFacilityWithCopay(item) ? (
                                                <div className="tier-container">
                                                    <div className="StarIcon-icon">
                                                        <TierIcon
                                                            fill={getTierBgColor(
                                                                item?.override_tier ||
                                                                    item?.default_tier
                                                            )}
                                                        />
                                                        <div className="final-tier">
                                                            {item?.override_tier ||
                                                                item?.default_tier}
                                                        </div>
                                                    </div>
                                                    <div className="subtext-final-tier">
                                                        Tier
                                                        {item?.override_tier ||
                                                            item?.default_tier}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="StarIcon-icon">
                                                        <FacilityIcon className="facilityIcon-color" />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="result-card-title badge-margin mat-card-header">
                                            <div className="mat-card-header-text">
                                                <div className="mat-card-subtitle">
                                                    <div className="result-card-title">
                                                        {/* <span> 0.24 miles · </span> */}
                                                        <span>
                                                            <a
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                href={`https://maps.google.com/?q=${
                                                                    item?.addr_ln_1
                                                                        ? item?.addr_ln_1
                                                                        : ""
                                                                }${
                                                                    item?.addr_ln_2
                                                                        ? item?.addr_ln_2
                                                                        : ""
                                                                }${
                                                                    item?.city
                                                                }, ${
                                                                    item?.state_cd
                                                                } ${
                                                                    item?.zipcode
                                                                }`}
                                                            >
                                                                {
                                                                    item?.addr_ln_1
                                                                }
                                                                ,
                                                                {
                                                                    item?.addr_ln_2
                                                                }{" "}
                                                                {item?.city},{" "}
                                                                {item?.state_cd}{" "}
                                                                {item?.zipcode}
                                                            </a>
                                                        </span>
                                                    </div>
                                                    <div></div>
                                                    <div className="result-card-title">
                                                        <a
                                                            href={`tel:${item?.phone_number}`}
                                                        >
                                                            {item?.phone_number}
                                                        </a>
                                                    </div>
                                                    <div className="result-card-title ">
                                                        <span>
                                                            {" "}
                                                            In Network{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {!isFacilityWithCopay(item) &&
                                            getCutomMessageContent(
                                                currentProviderDetails,
                                                item
                                            )}
                                        <CardContent className="result-card-title mat-card-content card-footer-action">
                                            {isFacilityWithCopay(item) ? (
                                                <Typography
                                                    component={"div"}
                                                    variant="body2"
                                                    className="alignment-mat-icon"
                                                >
                                                    {facilityContent()}
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    component={"div"}
                                                    variant="body2"
                                                    className="alignment-mat-icon"
                                                >
                                                    {visitContent(
                                                        currentProviderDetails,
                                                        item
                                                    )}
                                                </Typography>
                                            )}
                                        </CardContent>
                                        <CardContent className="result-card-title mat-card-content card-footer-action">
                                            <div className="card-footer-secondary">
                                                <CheckCircleIcon
                                                    color="success"
                                                    className="CheckCircleIcon"
                                                />
                                                <div>
                                                    Clean and clear medical
                                                    license
                                                </div>
                                            </div>

                                            <Button
                                                variant="contained"
                                                onClick={() => handleOpen(item)}
                                            >
                                                Details
                                            </Button>
                                        </CardContent>
                                    </>
                                )}
                            </Card>
                        </div>
                    )
                })}
                <div className="mb-2 results-workflow-container pb-3 pt-3">
                    <Button
                        onClick={handleLoadMore}
                        variant="text"
                        aria-label="Need help?"
                        disabled={
                            providerDetailsDataLength <= providerDetails.length
                        }
                        endIcon={
                            checkProviderLengthStatus() ? (
                                <ExpandMoreIcon />
                            ) : (
                                <></>
                            )
                        }
                    >
                        {providerDetailsDataLength > providerDetails.length
                            ? "Load More Results"
                            : "End of Results"}
                    </Button>
                </div>
            </div>
        </>
    )
}

ProviderList.propTypes = {
    handleOpen: PropTypes.func,
    providerDetails: PropTypes.any,
    handleLoadMore: PropTypes.func,
    currentProviderDetails: PropTypes.any,
    providerDetailsDataLength: PropTypes.any
}
export default ProviderList
