import React, { useEffect, useState } from "react"
import { PropTypes } from "prop-types"

import Typography from "@mui/material/Typography"
import PersonSharpIcon from "@mui/icons-material/PersonSharp"
import CloseIcon from "@mui/icons-material/Close"
import IconButton from "@mui/material/IconButton"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import CallIcon from "@mui/icons-material/Call"
import DirectionsIcon from "@mui/icons-material/Directions"
import { Divider } from "@mui/material"
import { useSelector } from "react-redux"
import { ReactComponent as FacilityIcon } from "../../assets/images/prov_facilityIcon.svg"
import { ReactComponent as TierIcon } from "../../assets/images/tier_starIcon.svg"
import {
    getTierBgColor,
    product,
    isFacilityWithCopay
} from "../../utils/common"
import "./PhysicianDetails.css"

const PhysicianDetails = (props) => {
    const { currentProviderData } = props
    const [productTool, setProductTool] = useState(null)
    const { userInfo = {} } = useSelector((state) => state)

    useEffect(() => {
        setProductTool(product(userInfo))
    }, [userInfo])

    const providerVisit = (item) => {
        return (
            <>
                {item?.override_rate || item?.default_rate ? (
                    <div>
                        <b>
                            {item?.override_rate !== null ||
                            item?.default_rate !== null
                                ? "$"
                                : ""}
                            {item?.override_rate !== null
                                ? item?.override_rate
                                : item?.default_rate}
                        </b>{" "}
                        out-of-pocket cost for :{" "}
                        {item?.benefitcode_text ||
                            "Office Visit for New Patient: Normal"}
                        <br />
                    </div>
                ) : (
                    ""
                )}
            </>
        )
    }
    const getCutomMessageContent = (item) => {
        if (item && item.title === "procedureCode") {
            return ""
        } else {
            return (
                <div className="provider-custom-message text-center p-2">
                    <div>{item?.custom_message}</div>
                </div>
            )
        }
    }
    const procedureVisit = (item) => {
        return (
            <>
                {item?.outpatient_rate ? (
                    <div>
                        <b>
                            {item?.outpatient_rate !== null ? "$" : ""}
                            {item?.outpatient_rate}
                        </b>{" "}
                        out-of-pocket cost for : Outpatient Visit
                        <br />
                    </div>
                ) : (
                    ""
                )}
                {item?.inpatient_rate ? (
                    <div>
                        <b>
                            {item?.inpatient_rate !== null ? "$" : ""}
                            {item?.inpatient_rate}
                        </b>{" "}
                        out-of-pocket cost for : Inpatient Visit
                        <br />
                    </div>
                ) : (
                    ""
                )}
            </>
        )
    }
    const facilityContent = () => {
        return (
            <div className="facilityResultListModal">
                <b>
                    Your facility out-of-pocket cost will be based on the tier
                    of the physician who treats you at the facility. To see
                    those facility costs by tier, please see your benefit
                    summary or perform a search for the procedure or service
                    your physician will perform at the facility.
                </b>
            </div>
        )
    }
    return (
        <div className="PhysicianDetails-landing">
            <div className="PhysicianDetails-card">
                <div className="desig-header">
                    <div className="desig-left-section">
                        <div className="desig-seconday-section">
                            <div>
                                <PersonSharpIcon className="desig-left-icon" />
                                <div className="desig-seconday-section-cont">
                                    <Typography
                                        sx={{ fontSize: 16 }}
                                        color="text.secondary"
                                        component="div"
                                        gutterBottom
                                        className="name-flds"
                                    >
                                        {currentProviderData?.provider_name}
                                    </Typography>
                                    <Typography
                                        sx={{ mb: 1.5 }}
                                        component="div"
                                        color="text.secondary"
                                    >
                                        {
                                            currentProviderData?.speciality_category
                                        }
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {!isFacilityWithCopay(currentProviderData) ? (
                            <div className="tier-container">
                                <div className="StarIcon-icon">
                                    <TierIcon
                                        fill={getTierBgColor(
                                            currentProviderData?.override_tier ||
                                                currentProviderData?.default_tier
                                        )}
                                    />
                                    <div className="final-tier">
                                        {currentProviderData?.override_tier ||
                                            currentProviderData?.default_tier}
                                    </div>
                                </div>
                                <div className="modal-subtext-final-tier">
                                    Tier
                                    {currentProviderData?.override_tier ||
                                        currentProviderData?.default_tier}
                                </div>
                            </div>
                        ) : (
                            <div>
                                <div className="StarIcon-icon">
                                    <FacilityIcon
                                        className={
                                            productTool === 1
                                                ? "facilityIcon-coupe"
                                                : "facilityIcon-simplePay"
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="onClose-icon">
                        <IconButton onClick={props.onClose}>
                            <CloseIcon />
                        </IconButton>
                    </div>
                </div>
                <Divider variant="middle" className="mx-0" />
                <div className="physician-content-container">
                    <div className="left-icon">
                        <a href={`tel:${currentProviderData?.phone_number}`}>
                            <CallIcon className="EventAvailableIcon" />
                            <br />
                            Call
                        </a>
                    </div>
                    <div className="right-icon">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={`https://maps.google.com/?q=${
                                currentProviderData?.addr_ln_1
                                    ? currentProviderData?.addr_ln_1
                                    : ""
                            }${
                                currentProviderData?.addr_ln_2
                                    ? currentProviderData?.addr_ln_2
                                    : ""
                            }${currentProviderData?.city}, ${
                                currentProviderData?.state_cd
                            } ${currentProviderData?.zipcode}`}
                        >
                            <DirectionsIcon className="EventAvailableIcon" />
                            <br />

                            <span>Directions</span>
                        </a>
                    </div>
                </div>
                <Divider variant="middle" className="mx-0" />
                {!isFacilityWithCopay(currentProviderData) ? (
                    <>
                        {getCutomMessageContent(currentProviderData)}
                        <div className="physician-middle-cont">
                            <div className="procedureVisitListModal">
                                {providerVisit(currentProviderData)}
                                {procedureVisit(currentProviderData)}
                            </div>
                        </div>
                    </>
                ) : (
                    <div>
                        <Typography
                            component={"div"}
                            variant="body2"
                            className="alignment-mat-icon"
                        >
                            {facilityContent()}
                        </Typography>
                    </div>
                )}
                <Divider variant="middle" className="mx-0" />
                <div className="card-footer-secondary">
                    <CheckCircleIcon
                        color="success"
                        className="CheckCircleIcon"
                    />
                    <div>Clean and clear medical license</div>
                </div>
            </div>
        </div>
    )
}

PhysicianDetails.propTypes = {
    onClose: PropTypes.any,
    currentProviderData: PropTypes.any
}

export default PhysicianDetails
